import { Controller } from "@hotwired/stimulus"

// Make sure Turbo is imported
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  static targets = ["message"]
  static values = {
    email: String
  }

  connect() {
    console.log("resend confirmation controller connected")
  }

  resend(event) {
    event.preventDefault()

    const button = event.currentTarget
    button.disabled = true
    const originalButtonHtml = button.innerHTML
    button.innerHTML = '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Sending...'

    // Get the email from the data attribute
    const email = this.emailValue

    // Always use the dedicated resend endpoint instead of the form action
    fetch('/resend_confirmation', {
      method: 'POST',
      body: JSON.stringify({ email: email }),
      headers: {
        "Content-Type": "application/json",
        "Accept": "text/vnd.turbo-stream.html",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      credentials: 'same-origin'
    })
    .then(response => response.text())
    .then(html => {
      // Replace the entire alert content with the new message
      this.element.innerHTML = html
      button.disabled = false
    })
    .catch(error => {
      console.error("Error:", error)
      button.disabled = false
      button.innerHTML = originalButtonHtml
    })
  }
}

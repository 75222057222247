import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["timer", "button", "timerMessage"]
  static values = {
    endsAt: String,
  }

  connect() {
    console.log("countdown controller connected")

    // Get the value directly from the data attribute
    const endsAtValue = this.timerTarget.getAttribute('data-countdown-ends-at-value')
    console.log("Direct attribute read:", endsAtValue)

    if (endsAtValue) {
      console.log("Starting countdown with:", endsAtValue)
      this.startCountdown(new Date(endsAtValue))
    } else {
      console.warn("No valid endsAt value found")
      // If no valid end time, show the button
      this.showButton()
    }
  }

  disconnect() {
    if (this.countdownInterval) {
      clearInterval(this.countdownInterval)
    }
  }

  startCountdown(endTime) {
    // Initial state setup
    if (this.hasButtonTarget) {
      this.buttonTarget.style.display = 'none'
      this.buttonTarget.disabled = true
    }
    this.timerTarget.style.display = 'inline'
    this.timerMessageTarget.style.display = 'inline'

    const updateTimer = () => {
      const now = new Date()
      const timeLeft = endTime - now

      if (timeLeft <= 0) {
        clearInterval(this.countdownInterval)
        this.showButton()
        return
      }

      const minutes = Math.floor(timeLeft / 60000)
      const seconds = Math.floor((timeLeft % 60000) / 1000)

      this.timerTarget.textContent =
        `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`
    }

    // Run immediately and then start interval
    updateTimer()
    this.countdownInterval = setInterval(updateTimer, 1000)
  }

  showButton() {
    this.timerTarget.style.display = 'none'
    this.timerMessageTarget.style.display = 'none'
    if (this.hasButtonTarget) {
      this.buttonTarget.style.display = 'block'
      this.buttonTarget.disabled = false
    }
  }

  // Add value change callback
  endsAtValueChanged() {
    console.log("endsAt value changed to:", this.endsAtValue)
    if (this.endsAtValue) {
      this.startCountdown(new Date(this.endsAtValue))
    }
  }
}
